@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Spinner_Loader__3cZx8,
.Spinner_Loader__3cZx8:before,
.Spinner_Loader__3cZx8:after {
  border-radius: 50%;
}
.Spinner_Loader__3cZx8 {
  color: #521751;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.Spinner_Loader__3cZx8:before,
.Spinner_Loader__3cZx8:after {
  position: absolute;
  content: '';
}
.Spinner_Loader__3cZx8:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: Spinner_load2__z32WR 2s infinite ease 1.5s;
  animation: Spinner_load2__z32WR 2s infinite ease 1.5s;
}
.Spinner_Loader__3cZx8:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: Spinner_load2__z32WR 2s infinite ease;
  animation: Spinner_load2__z32WR 2s infinite ease;
}
@-webkit-keyframes Spinner_load2__z32WR {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_load2__z32WR {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.OrdersMap_OrdersMap__wd0Pz {
    height: calc(100vh - 65px);
}
